import { Component, OnInit, Input, ElementRef } from '@angular/core';
import { StorageService } from '../storage.service';
import { LoggerService } from '../logger.service';

@Component({
  selector: 'app-activity',
  templateUrl: './activity.component.html',
  styleUrls: ['./activity.component.css']
})
export class ActivityComponent implements OnInit {
  protected isMaximized: boolean;
  @Input() quickCommentList: string[];
  constructor(
    protected storageService: StorageService,
    private _doc: ElementRef,
    protected loggerService: LoggerService
  ) {
    this.isMaximized = true;
  }

  ngOnInit() {}

  protected loadComment(index: number) {
    this.loggerService.logger.logDebug('Oracle: Loading a Quick Comment');
    if (this.storageService.callNotes.length > 0) {
      this.storageService.callNotes += '\n' + this.quickCommentList[index];
    } else {
      this.storageService.callNotes = this.quickCommentList[index];
    }
  }

  protected parseSearchRecord(searchRecord) {
    const keys = Object.keys(searchRecord.fields);
    let nameKey;
    for (let i = 0; i < keys.length; i++) {
      if (keys[i].includes('Name')) {
        nameKey = keys[i];
        break;
      }
    }
    let name = searchRecord.fields[nameKey].Value;
    name = searchRecord.displayName
      ? searchRecord.displayName + ': ' + name
      : searchRecord.RecordType + ': ' + name;
    return name;
  }

  protected submit() {
    this.loggerService.logger.logDebug('Oracle: Notes submit');
    this.storageService.updateCommEvent(
      'SVCMCA_WRAPUP_INTERACTION_NOTES',
      this._doc.nativeElement.querySelector('#Notes').value
    );
  }

  protected onCallNotesChange(event: any) {
    this.storageService.setDescription(event.srcElement.value.trim());
  }
}
