import { Injectable } from '@angular/core';
import {
  IInteraction,
  SearchRecords,
  RecordItem,
  IRecordItem
} from '@amc-technology/davinci-api';
 import { BehaviorSubject, Subject } from 'rxjs/';


@Injectable()
export class StorageService {
  private _currentInteraction: IInteraction;
  private _currentCommEvent: any;
  private _currentActivity: any;
  private _searchRecords: IRecordItem[];
  public searchReturnedSingleResult: boolean;
  public searchRecordsSubject;
  public callNotes: string;
  public scenarioInteractionMappings: {
    [scenarioId: string]: {};
  };

  constructor() {
    this._searchRecords = [];
    this.searchRecordsSubject = new Subject();
    this.callNotes = '';
    this.scenarioInteractionMappings = {};
  }

  /**
   * Getter searchRecords
   * @return {SearchRecords}
   */
  public get searchRecords(): IRecordItem[] {
    return this._searchRecords;
  }

  public setDescription(description: string) {
    this.callNotes = description;
    this.saveToStorage();
  }

  public setScenarioMapping(obj: any) {
    this.scenarioInteractionMappings = obj;
    this.saveToStorage();
  }

  public getScenarioMapping() {
    return this.scenarioInteractionMappings;
  }

  /**
   * Setter searchRecords
   * @param {SearchRecords} value
   */
  public set searchRecords(value: IRecordItem[]) {
    this._searchRecords = value;
    if (value != null) {
      this.searchRecordsSubject.next(value);
    }

    this.saveToStorage();
  }

  /**
   * Getter currentActivity
   * @return {any}
   */
  public get currentActivity(): any {
    return this._currentActivity;
  }
  /**
   * Getter currentInteraction
   * @return {IInteraction}
   */
  public get currentInteraction(): IInteraction {
    return this._currentInteraction;
  }

  /**
   * Getter currentCommEvent
   * @return {any}
   */
  public get currentCommEvent(): any {
    return this._currentCommEvent;
  }
  /**
   * Setter currentActivity
   * @param {any} value
   */
  public set currentActivity(value: any) {
    this._currentActivity = value;
    this.saveToStorage();
  }
  /**
   * Setter currentCommEvent
   * @param {any} value
   */
  public set currentCommEvent(value: any) {
    this._currentCommEvent = value;
    this.saveToStorage();
  }
  /**
   * Setter currentInteraction
   * @param {IInteraction} value
   */
  public set currentInteraction(value: IInteraction) {
    this._currentInteraction = value;
    this.saveToStorage();
  }

  public saveToLocallStorage(){
    this.saveToStorage();
  }

  public getCAD() {
    if (this._currentInteraction) {
      return this._currentInteraction.details.fields;
    }
  }

  public updateCommEvent(field, data) {
    this._currentCommEvent[field] = data;
    this.saveToStorage();
  }

  public updateActivity(data: any, field: string) {
    this._currentActivity[field] = data;
  }

  public onInteractionEnd() {
    this.currentInteraction = null;
    this.currentCommEvent = null;
    this.currentActivity = null;
    this.searchRecords = null;
    this.callNotes = '';
    this.saveToStorage();
    this.scenarioInteractionMappings = {};
  }

  public syncStorage() {
    const item = JSON.parse(localStorage.getItem('scenario'));
    if (item) {
      this._currentActivity = item.currentActivity;
      this._currentCommEvent = item.currentCommEvent;
      this._currentInteraction = item.currentInteraction;
      this._searchRecords = item.searchRecords;
      this.searchReturnedSingleResult = item.searchReturnedSingleResult;
      this.searchRecordsSubject.next(this._searchRecords);
      this.callNotes = item.callNotes;
      this.scenarioInteractionMappings = item.scenarioInteractionMappings;
    }
  }

  private saveToStorage() {
    localStorage.setItem(
      'scenario',
      JSON.stringify({
        currentInteraction: this._currentInteraction,
        currentActivity: this._currentActivity,
        currentCommEvent: this._currentCommEvent,
        searchRecords: this._searchRecords,
        searchReturnedSingleResult: this.searchReturnedSingleResult,
        callNotes: this.callNotes,
        scenarioInteractionMappings: this.scenarioInteractionMappings
      })
    );
  }
}
