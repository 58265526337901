import { Component, OnInit } from '@angular/core';
import * as api from '@amc-technology/davinci-api';
import { Application } from '@amc-technology/applicationangularframework';

import {
  Logger,
  LOG_SOURCE,
  INTERACTION_STATES,
  SearchRecords,
  IInteraction,
  INTERACTION_DIRECTION_TYPES
} from '@amc-technology/davinci-api';

import { bind } from 'bind-decorator';
import { StorageService } from '../storage.service';
import { IQuickCreateCAD } from '../model/IQuickCreateCAD';
import { LoggerService } from '../logger.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html'
})
export class HomeComponent extends Application implements OnInit {
  protected quickCommentList: string[];
  protected quickCreateOptions: any;
  protected QuickCreateCADMap: IQuickCreateCAD[];
  protected QuickCreateCAD: string;
  private screenPopOnAlert: boolean;
  private phoneNumberFormat: Object;
  public clickToDialPhoneReformatMap: Object;
  private _scenarioInteractionMappings: Object;

  constructor(private loggerService: LoggerService, protected storage: StorageService) {
    super(loggerService.logger);
    this.loggerService.logger.logDebug('OECHome: constructor start');
    this.storage.syncStorage();
    this.QuickCreateCADMap = [];
    this.phoneNumberFormat = {};
    this.clickToDialPhoneReformatMap = {};
    this.screenPopOnAlert = true;
    this.loggerService.logger.logDebug('OECHome: constructor complete');
    this._scenarioInteractionMappings = {};
    this._scenarioInteractionMappings = this.storage.getScenarioMapping();
  }

  async ngOnInit() {
    api.registerOnLogout(async () => {
      await this.logger.pushLogsAsync();
    });

    this.loggerService.logger.logDebug('OECHome: ngOnInit start');
    await this.loadConfig();
    this.quickCommentList = <string[]>this.appConfig.variables.QuickComments;

    this.bridgeScripts = this.bridgeScripts.concat([
      this.appConfig['variables']['orgURL'] + '/crmUI/js/mcaInteractionV1.js',
      this.getBridgeURL()
    ]);
    this.screenPopOnAlert = Boolean(this.appConfig['variables']['screenPopOnAlert']);
    /*this.quickCreateOptions =
      config['QuickCreate']['variables']['QuickCreateEntities'];

    this.QuickCreateCAD = config['QuickCreate']['variables']['QuickCreateCAD'];
    if (this.QuickCreateCAD) {
      const CADconfig = this.QuickCreateCAD.split(';');
      for (const item of CADconfig) {
        const fields = item.split('|');
        this.QuickCreateCADMap.push({
          channelKey: fields[0],
          entityName: fields[1],
          apiKey: fields[2]
        });
      }
    }
    */

    await super.ngOnInit();
    this.loggerService.logger.logDebug('OECHome: ngOnInit complete');
    this.bridgeEventsService.subscribe('ctd', this.clickToDial);

    await this.readConfig(this.appConfig);

  }

  private async readConfig(config: api.IAppConfiguration) {
    try {
      this.logger.logDebug(
        'Oracle - Home : START : Reading Configuration from Oracle App'
      );
      const configPhoneFormat = config.variables['PhoneNumberFormat'];
      if (typeof configPhoneFormat === 'string') {
        const tempFormat = String(configPhoneFormat).toLowerCase();
        this.phoneNumberFormat[tempFormat] = tempFormat;
      } else {
        this.phoneNumberFormat = configPhoneFormat;
      }

      if (config.variables['ClickToDialPhoneReformatMap']) {
        this.clickToDialPhoneReformatMap = config.variables['ClickToDialPhoneReformatMap'];
      }
    } catch (error) {
      this.logger.logError(
        'OECHome: ERROR : Reading Configuration. Config Info : ' +
        JSON.stringify(config) +
        '. Error Information : ' +
        JSON.stringify(error)
      );
    }
  }
  @bind
  protected clickToDial(event: any) {
    const numberToDial = this.clickToDialFormatPhoneNumber(event);
    api.clickToDial(numberToDial);
  }

  protected clickToDialFormatPhoneNumber(number: any) {
    const configuredInputFormats = Object.keys(this.clickToDialPhoneReformatMap);
    for (let i = 0; i < configuredInputFormats.length; i++) {
      let formatCheck = true;
      if (number.length === configuredInputFormats[i].length) {
        // Length of incoming number matches length of a configured input format
        // Now Validate # of X's in input/output
        const inputFormat = configuredInputFormats[i];
        const outputFormat = this.clickToDialPhoneReformatMap[configuredInputFormats[i]];
        const arrInputDigits = [];
        let outputNumber = '';
        let outputIncrement = 0;
        if (((inputFormat.match(/x/g) || []).length) !== ((outputFormat.match(/x/g) || []).length)) {
          continue;
        }
        if (((inputFormat.match(/\(/g) || []).length) !== ((number.match(/\(/g) || []).length)) {
          continue;
        }
        if (((inputFormat.match(/-/g) || []).length) !== ((number.match(/-/g) || []).length)) {
          continue;
        }

        for (let j = 0; j < inputFormat.length; j++) {
          if (inputFormat[j] === 'x') {
            arrInputDigits.push(j);
          } else if (inputFormat[j] !== '?' && number[j] !== inputFormat[j]) {
            formatCheck = false;
            break;
          }
        }
        if (formatCheck) {
          for (let k = 0; k < outputFormat.length; k++) {
            if (outputFormat[k] === 'x') {
              outputNumber = outputNumber + number[arrInputDigits[outputIncrement]];
              outputIncrement++;
            } else {
              outputNumber = outputNumber + outputFormat[k];
            }
          }
          return outputNumber;
        }
      }
    }
    return number;
  }

  protected isEmpty() {
    return !(
      this.quickCreateOptions && Object.keys(this.quickCreateOptions).length > 0
    );
  }

  private buildParams(type) {
    const inData = {};
    const cad = this.storage.getCAD();
    if (this.storage.getCAD() && this.QuickCreateCADMap) {
      inData['SVCMCA_PHONE_AREA'] = this.parseAreaCode(cad['Phone'].Value);
      inData['SVCMCA_ANI'] = this.parseANI(cad['Phone'].Value);
      for (const obj of this.QuickCreateCADMap) {
        if (obj.entityName === type && cad[obj.channelKey]) {
          inData[obj.apiKey] = cad[obj.channelKey].Value;
        }
      }
    }
    return inData;
  }

  protected quickCreate(type) {
    this.loggerService.logger.logDebug(
      `OECHome: Screenpop new OEC object of type: ${JSON.stringify(type)}`
    );
    this.bridgeEventsService.sendEvent('quickCreate', {
      type: type,
      inData: this.buildParams(type)
    });
  }

  /**
   * This is called before initializeComplete to register listeners for any AMC events
   */
  async registerForAmcEvents() {
    await super.registerForAmcEvents();
    api.registerOnPresenceChanged(this.changePresence);
  }

  @bind
  private changePresence(pres: string, reason?: string): Promise<void> {
    return this.bridgeEventsService.sendEvent('changePresence', {
      presence: pres,
      reason: reason
    });
  }

  /**
   * This takes crm entities(as returned from the bridge) and converts them to a SearchRecords object
   * @param crmResults Entities received from crm/bridge
   */

  protected formatCrmResults(crmResults: any): api.SearchRecords {
    console.log(crmResults);
    const result = new api.SearchRecords();

    let recordItem: api.RecordItem = null;
    if (crmResults.SVCMCA_CONTACT_ID) {
      recordItem = new api.RecordItem(
        crmResults.SVCMCA_CONTACT_ID,
        crmResults.channel,
        crmResults.channel
      );
    }
    if (recordItem !== null) {
      if (crmResults.SVCMCA_CONTACT_NAME) {
        recordItem.setFullName('name', 'Name:', crmResults.SVCMCA_CONTACT_NAME);
      }
      if (crmResults.SVCMCA_CONTACT_PRIM_ORG_NAME) {
        recordItem.setAccountName(
          'accountName',
          'Account:',
          crmResults.SVCMCA_CONTACT_PRIM_ORG_NAME
        );
      }
      if (crmResults.SVCMCA_EMAIL) {
        recordItem.setEmail('email', 'Email:', crmResults.SVCMCA_EMAIL);
      }
      result.addSearchRecord(recordItem);
    }

    return result;
  }

  private parseAreaCode(phoneNumber: string): string {
    phoneNumber = phoneNumber.replace(new RegExp('[()\\+\\-\\s]', 'g'), '');
    if (phoneNumber.charAt(0) === '1') {
      return phoneNumber.substr(1, 3);
    } else {
      return phoneNumber.substr(0, 3);
    }
  }

  private parseANI(phoneNumber: string): string {
    if (phoneNumber) {
      phoneNumber.trim();
      phoneNumber = phoneNumber.replace(new RegExp('[()\\+\\-\\s]', 'g'), '');
      return phoneNumber.charAt(0) === '1'
        ? phoneNumber.substr(4, phoneNumber.length - 1)
        : phoneNumber.substr(3, phoneNumber.length - 1);
    }
  }

  protected popSearchInfo(event) {
    this.loggerService.logger.logDebug(
      'OECHome: Screenpop selected caller information',
      api.ERROR_CODE.ACTIVITY
    );
    const record = this.storage.searchRecords.find(arr => arr.id === event);
    const inData = this.storage.currentCommEvent;
    if (record.fields.FullName) {
      inData.SVCMCA_CONTACT_NAME = record.fields.FullName.Value;
    }
    this.bridgeEventsService.sendEvent('searchPop', inData);
  }

  private generateCommEvent(interaction: IInteraction) {
    const event: any = {};
    event.SVCMCA_COMMUNICATION_DIRECTION =
      interaction.direction === INTERACTION_DIRECTION_TYPES.Inbound
        ? 'ORA_SVC_INBOUND'
        : 'ORA_SVC_OUTBOUND';
    const cad = this.storage.getCAD();
    if (cad && this.QuickCreateCADMap) {
      for (const obj of this.QuickCreateCADMap) {
        if (cad[obj.channelKey]) {
          event[obj.apiKey] = cad[obj.channelKey].Value;
        }
      }
    }

    event.SVCMCA_ANI = event.SVCMCA_PHONE_LOCAL = this.parseANI(
      interaction.details.fields.Phone.Value
    );
    event.SVCMCA_PHONE_AREA = this.parseAreaCode(
      interaction.details.fields.Phone.Value
    );

    return event;
  }


  protected formatPhoneNumber(
    inputNumber: string,
    phoneNumberFormat: Object
  ): string {
    try {
      this.logger.logTrace(
        'OECHome: START : Formatting Phone Number. Input Number : ' +
        inputNumber +
        '. Configured Format : ' +
        JSON.stringify(phoneNumberFormat)
      );
      const configuredInputFormats = Object.keys(phoneNumberFormat);
      for (let index = 0; index < configuredInputFormats.length; index++) {
        let formatCheck = true;
        const inputFormat = configuredInputFormats[index];
        const outputFormat = phoneNumberFormat[inputFormat];
        if (inputFormat.length === inputNumber.length) {
          const arrInputDigits = [];
          let outputNumber = '';
          let outputIncrement = 0;
          if (
            (inputFormat.match(/x/g) || []).length !==
            (outputFormat.match(/x/g) || []).length
          ) {
            continue;
          }
          for (let j = 0; j < inputFormat.length; j++) {
            if (inputFormat[j] === 'x') {
              arrInputDigits.push(j);
            } else if (
              inputFormat[j] !== '?' &&
              inputNumber[j] !== inputFormat[j]
            ) {
              formatCheck = false;
              break;
            }
          }
          if (formatCheck) {
            for (let j = 0; j < outputFormat.length; j++) {
              if (outputFormat[j] === 'x') {
                outputNumber =
                  outputNumber + inputNumber[arrInputDigits[outputIncrement]];
                outputIncrement++;
              } else {
                outputNumber = outputNumber + outputFormat[j];
              }
            }
            this.logger.logTrace(
              'OECHome: END : Formatting Phone Number. Input Number : ' +
              inputNumber +
              '. Configured Format : ' +
              JSON.stringify(phoneNumberFormat) +
              '. Output Number : ' +
              outputNumber
            );
            return outputNumber;
          }
        }
      }
    } catch (error) {
      this.logger.logError(
        'OECHome: ERROR : Formatting Phone Number. Input Number : ' +
        inputNumber +
        '. Configured Format : ' +
        JSON.stringify(phoneNumberFormat) +
        '. Error Information : ' +
        JSON.stringify(error)
      );
    }
    this.logger.logTrace(
      'OECHome: END : Formatting Phone Number. Input Number : ' +
      inputNumber +
      '. Configured Format : ' +
      JSON.stringify(phoneNumberFormat) +
      '. Output Number : ' +
      inputNumber
    );
    return inputNumber;
  }

  protected async onInteraction(interaction: api.IInteraction): Promise<api.SearchRecords> {
    this.loggerService.logger.logDebug(
      `OECHome: Interaction recieved: ${JSON.stringify(interaction)}`,
      api.ERROR_CODE.INTERACTION_EVENT
    );
    if (interaction.details && interaction.details.fields && interaction.details.fields.Phone && interaction.details.fields.Phone.Value) {
      interaction.details.fields.Phone.Value = this.formatPhoneNumber(interaction.details.fields.Phone.Value, this.phoneNumberFormat);
    }
    return new Promise<api.SearchRecords>(async (resolve, reject) => {
      let records: api.SearchRecords = null;
      this.storage.currentInteraction = interaction;
      if (!this.storage.currentCommEvent) {
        this.storage.currentCommEvent = this.generateCommEvent(interaction);
      }
      const isNewScenario = this.processIfNewScenario(interaction);
      const data = {
        commEvent: this.storage.currentCommEvent,
        isNewScenario: isNewScenario
      }

      if (interaction.state === INTERACTION_STATES.Alerting) {
        this.bridgeEventsService
          .sendEvent('newCommEvent', data)
          .then(newComm => {
            records = this.formatCrmResults(newComm);
            if (this.screenPopOnAlert) {
              this.bridgeEventsService
                .sendEvent('startCommEvent', this.storage.currentCommEvent)
                .then(startComm => {
                  records = this.formatCrmResults(startComm);
                  this.storage.searchRecords = records.toJSON();
                });
            }
          });
      } else if (interaction.state === INTERACTION_STATES.Connected) {
        if (interaction.direction === INTERACTION_DIRECTION_TYPES.Outbound) {
          this.bridgeEventsService
            .sendEvent('newCommEvent', this.storage.currentCommEvent)
            .then(newComm => {
              records = this.formatCrmResults(newComm);
              this.storage.searchRecords = records.toJSON();
            });
        }

        if (
          !this.screenPopOnAlert ||
          interaction.direction === INTERACTION_DIRECTION_TYPES.Outbound
        ) {
          this.bridgeEventsService
            .sendEvent('startCommEvent', this.storage.currentCommEvent)
            .then(startComm => {
              records = this.formatCrmResults(startComm);
              this.storage.searchRecords = records.toJSON();
            });
        }
      } else if (interaction.state === INTERACTION_STATES.Disconnected) {
        this.loggerService.logger.logDebug(
          `OECHome: Disconnect interaction received:${JSON.stringify(
            interaction
          )}`,
          api.ERROR_CODE.DISCONEECTED_INTERACTION
        );
        this.storage.updateCommEvent(
          'SVCMCA_WRAPUP_INTERACTION_NOTES', this.storage.callNotes);
        this.bridgeEventsService.sendEvent(
          'closeCommEvent',
          this.storage.currentCommEvent
        );
        this.storage.onInteractionEnd();
        delete this._scenarioInteractionMappings[interaction.scenarioId];
        this.storage.setScenarioMapping(this._scenarioInteractionMappings);
        this.storage.saveToLocallStorage();
      }

      resolve(records);
    });
  }
  protected processIfNewScenario(interaction: api.IInteraction): boolean {
    try {
      this.logger.logTrace('Oracle - Home : START : Checking if the interaction is new or existing. Interaction Info : ' + JSON.stringify(interaction));
      if (!this._scenarioInteractionMappings.hasOwnProperty(interaction.scenarioId) &&
        (this.screenPopOnAlert || interaction.state !== api.INTERACTION_STATES.Alerting)
      ) {
        this._scenarioInteractionMappings[interaction.scenarioId] = new Set();
        this._scenarioInteractionMappings[interaction.scenarioId].add(interaction.interactionId);

        this.logger.logInformation('Oracle - Home : New Scenario with Scenario ID : ' + interaction.scenarioId);
        this.logger.logTrace('Oracle - Home : END : Checking if the interaction is new or existing. Interaction Info : ' + JSON.stringify(interaction));
        this.storage.setScenarioMapping(this._scenarioInteractionMappings);
        this.storage.saveToLocallStorage();
        return true;
      } else {
        return false;
      }
    } catch (error) {
      this.logger.logError('Oracle - Home : ERROR : Checking if the interaction is new or existing. Interaction Info : ' + JSON.stringify(interaction) + '. Error Information : ' + JSON.stringify(error));
    }
    this.logger.logTrace('Oracle - Home : END : Checking if the interaction is new or existing. Interaction Info : ' + JSON.stringify(interaction));
  }

  /**
   * This retrieves the search layout/s
   */
  protected async getSearchLayout(): Promise<api.SearchLayouts> {
    throw new Error('Search Layouts not supported!');
  }

  /**
   * This function returns a unique id for the given user.
   * This id should be retrieved from the application(crm)
   */
  protected async getUserInfoHandler(): Promise<string> {
    throw new Error('Not implemented!');
  }

  /**
   * This method should return true if the toolbar is visible(maximized) and false otherwise(minimized)
   */
  protected async isToolbarVisible(): Promise<boolean> {
    throw new Error('Not implemented!');
  }

  /**
   * This method should save the given activity to the application.
   * If the activity id is defined this method should update the existing activity in the application.
   * @param activity The activity to save
   * @returns the id of the created or updated activity
   */
  protected async saveActivity(activity: api.IActivity): Promise<string> {
    throw new Error('Activities not supported!');
  }
}
