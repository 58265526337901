import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { StorageService } from '../storage.service';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})
export class SearchComponent implements OnInit {
  protected isSearchInformationMaximized: boolean;
  @Output() infoClicked = new EventEmitter();
  protected layout;
  constructor(protected storageService: StorageService) {
    this.isSearchInformationMaximized = true;
    this.layout = null;
    this.storageService.searchRecordsSubject.subscribe(data => {
      this.layout = this.parseRecord(data);
    });
    this.storageService.syncStorage();
  }

  ngOnInit() {}

  protected onInfoClicked(data) {
    console.log(data.srcElement.id);
    this.infoClicked.emit(data.srcElement.id);
  }

  protected parseRecord(records) {
    const layout = [];
    if (records && records.length > 0) {
      const keys = Object.keys(records[0].fields);
      for (let i = 0; i < keys.length; i++) {
        const field = records[0].fields[keys[i]];
        const display = field.Value;
        layout.push([field.DisplayName, display]);
      }
    }
    return layout;
  }
}
