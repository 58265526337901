import { IAnalytics, ITranscript } from './Transcript';
/**
 * @packageDocumentation
 * @module DaVinci_API
 */
import { RecordItem } from './RecordItem';
import { CHANNEL_TYPES } from './ChannelTypes';

/**
 * Represents an interaction, like a phone call, chat, SMS, or the like.
 * Contains transcription and analytics data if available.
 * The Interaction Sequence ID is to be set by the app calling [[setInteraction]]. If not provided, Framework will set it to 0 and increment
 * it every time a setInteraction is received for the scenario.
 * The Interaction Sequence Update ID will be incremented by Framework when an [[updateInteraction]] is received for the scenario.
 * Used by [[setInteraction]]
 */
export interface IInteraction {
  interactionId: string;
  scenarioId?: string;
  state?: INTERACTION_STATES;
  channelType?: CHANNEL_TYPES;
  direction: INTERACTION_DIRECTION_TYPES;
  details?: RecordItem;
  userFocus?: boolean;
  multiPartyState?: INTERACTION_MULTIPARTY_STATES;
  transcripts?: ITranscript;
  analytics?: IAnalytics;
  interactionSequenceId?: number;
  interactionSequenceUpdateId?: number;
}

/**
 * The state of an [[IInteraction]]
 */
export enum INTERACTION_STATES {
  Alerting,
  Connected,
  Disconnected,
  OnHold,
  Initiated
}

/**
 * The direction of an [[IInteraction]].
 *
 * Example:
 * - 'Outbound' if the agent is calling someone else
 * - 'Inbound' if someone is calling the agent
 * - 'Internal' if the agent is on a call with another agent
 */
export enum INTERACTION_DIRECTION_TYPES {
  Inbound,
  Outbound,
  Internal
}

export enum INTERACTION_MULTIPARTY_STATES {
  Consult,
  ConsultTransfer,
  ConsultConference,
  BlindTransfer,
  Transferred,
  Conferenced
}
