import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';

import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { CreateComponent } from './create/create.component';
import { SearchComponent } from './search/search.component';
import { ActivityComponent } from './activity/activity.component';
import { StorageService } from './storage.service';
import { LoggerService } from './logger.service';
import { ConfigurationService } from './configuration.service';


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    CreateComponent,
    SearchComponent,
    ActivityComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    FormsModule,
    RouterModule.forRoot([
      { path: '', component: HomeComponent, pathMatch: 'full' }
    ])
  ],
  providers: [
    ConfigurationService,
    {
      provide: APP_INITIALIZER,
      useFactory: (
        configService: ConfigurationService,
        loggerService: LoggerService
      ) => async () => {
        await configService.loadConfigurationData();
        await loggerService.initialize();
      },
      deps: [ConfigurationService, LoggerService],
      multi: true
    },

    StorageService, LoggerService],
  bootstrap: [AppComponent]
})
export class AppModule {}
