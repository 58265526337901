import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.css']
})
export class CreateComponent implements OnInit {
  isMaximized: boolean;
  @Input() quickCreateOptions: any;
  @Output() event = new EventEmitter();
  constructor() {
    this.isMaximized = true;
  }

  ngOnInit() {}

  protected qCreate(type) {
    this.event.emit(type);
  }

  protected getEntities() {
    return Object.keys(this.quickCreateOptions);
  }

  protected getDisplay(entity) {
    return entity.substring(0, entity.indexOf('|'));
  }
  protected getImage(entity) {
    return entity.substring(entity.indexOf('|') + 1);
  }
}
